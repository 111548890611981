/*
 * FC-Sprint2 project => fonts.css.css
 *
 * The project "diglin.eu" is property of FC-Sprint2
 *
 * Created at:
 * 6-okt-2016 @ 8:11:59
 *
 * Created by:
 * Andries van Weeren
 * a.weeren@fcroc.nl
 *//*
    Created on : 6-okt-2016, 8:11:59
    Author     : andries
*/

/*UBUNTU-FONTFACE*/
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: local('Ubuntu-Regular'), url(~ubuntu-fontface/fonts/ubuntu-regular-webfont.woff2) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
.sf-toolbar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 9999;
}